import { canvasLineColor } from "../const";

// Function to draw a horizontal line at the specified Y coordinate
export function drawHorizontalLine(context, width, y) {
  context.beginPath();
  context.moveTo(0, y);
  context.lineTo(width, y);
  context.strokeStyle = "#000000"; // Set line color
  context.stroke();
};

// Function to draw a vertical line at the specified X coordinate
export function drawVerticalLine(context, height, x) {
  context.beginPath();
  context.moveTo(x, 0);
  context.lineTo(x, height);
  context.strokeStyle = canvasLineColor; // Set line color
  context.stroke();
}

export function drawMultipleLines (canvas, horizontalLines, verticalLines) {
  // const canvas = canvasRef.current;
  const context = canvas.getContext('2d');

  horizontalLines.forEach(mouseY => {
    drawHorizontalLine(context, canvas.width, mouseY);
  });

  verticalLines.forEach(mouseX => {
    drawVerticalLine(context, canvas.height, mouseX);
  });
};