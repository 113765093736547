import { Badge } from '@aws-amplify/ui-react';
import './Pill.css';

const Pill = props => {
  const { display, value, handleClick } = props;

  return (
    <Badge className="pill">
      { display }
      <span className="cancel" onClick={() => handleClick(value) }>
          x
      </span>
    </Badge>
  )
};

export default Pill;