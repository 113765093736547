import "@aws-amplify/ui-react/styles.css";
import {  withAuthenticator, Button, View } from "@aws-amplify/ui-react";
import './App.css';
import Canvas from './Canvas/Canvas';

function App({ signOut }) {
  return (
    <View className="App">
      <Canvas>
        <Button className="signout-button" onClick={signOut}>Sign Out</Button>
      </Canvas>
    </View>
  );
}

export default withAuthenticator(App);