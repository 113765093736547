import { post } from 'aws-amplify/api';
import { Card, Button, Input, Grid } from '@aws-amplify/ui-react';
import { useRef, useEffect, useState } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@aws-amplify/ui-react';
import { drawHorizontalLine, drawMultipleLines, drawVerticalLine } from './helper';
import './Canvas.css';
import Pill from '../Pill/Pill';
import { FT_TO_METER, FT_TO_PIXEL, MARKER_LENGTH, MODE_X, MODE_Y, METRIC, IMPERIAL, minCanvasHeightInFt, minCanvasWidthInFt, minCanvasWidthInPixels, minCanvasHeightInPixels, canvasColor, copy, HORIZONTAL, VERTICAL, maxCanvasWidthInPixels, maxCanvasHeightInPixels, WIDTH, HEIGHT } from '../const';

const Canvas = props => {
  const { Canvas: canvasCopy } = copy;
  const canvasRef = useRef(null);
  const [mode, setMode] = useState(MODE_X);
  const [system, setSystem] = useState(IMPERIAL);
  const [canvasWidth, setCanvasWidth] = useState(minCanvasWidthInPixels);
  const [canvasHeight, setCanvasHeight] = useState(minCanvasHeightInPixels);
  const [horizontalLines, setHorizontalLines] = useState([]);
  const [verticalLines, setVerticalLines] = useState([]);
  const [hoverLeft, setHoverLeft] = useState(0);
  const [hoverTop, setHoverTop] = useState(0);
  const [canvasErrorMessage, setCanvasErrorMessage] = useState('');
  const [horizontalLineErrorMessage, setHorizontalLineErrorMessage] = useState('');
  const [verticalLineErrorMessage, setVerticalLineErrorMessage] = useState('');

  const [tempInputXValue, setTempInputXValue] = useState('');
  const [tempInputYValue, setTempInputYValue] = useState('');
  const [tempCanvasWidth, setTempCanvasWidth] = useState(minCanvasWidthInFt);
  const [tempCanvasHeight, setTempCanvasHeight] = useState(minCanvasHeightInFt);

  // convert px to real metric
  const convertTo = (px, includeSuffix = true, roundTo = 2) => {
    const ft = (px / FT_TO_PIXEL);
    if (system === IMPERIAL) {
      const result = parseFloat(ft.toFixed(roundTo));
      return includeSuffix ? `${result} feet` : result;
    }

    if (system === METRIC) {
      const result = parseFloat((ft * FT_TO_METER).toFixed(roundTo));
      return includeSuffix ? `${result} meters` : result;
    }
  };

  // convert real metric to px
  const deconvertTo = (num) => {
    const ft = system === IMPERIAL ? num : (num / FT_TO_METER);
    return ft * FT_TO_PIXEL;
  };

  const resetHover = () => {
    setHoverLeft(0);
    setHoverTop(0);
  };

  const drawMarkers = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    const increment = system === IMPERIAL ? FT_TO_PIXEL : (FT_TO_PIXEL / FT_TO_METER);
    const newWidth = getNewDimension(WIDTH);
    const newHeight = getNewDimension(HEIGHT);
    if (newWidth !== canvas.width) {
      canvas.width = newWidth;
    }
    if (newHeight !== canvas.height) {
      canvas.height = newHeight;
    }

    for (let i = 0; i < newWidth; i += increment) {
      drawVerticalLine(context, MARKER_LENGTH, i);
    }

    for (let i = 0; i < newHeight; i += increment) {
      drawHorizontalLine(context, MARKER_LENGTH, i);
    }
  };

  const removeLine = (value, arrayName) => {
    if (arrayName === HORIZONTAL) {
      let index = horizontalLines.indexOf(value);
      setHorizontalLines(horizontalLines.slice(0, index).concat(horizontalLines.slice(index + 1)))
    }

    if (arrayName === VERTICAL) {
      let index = verticalLines.indexOf(value);
      setVerticalLines(verticalLines.slice(0, index).concat(verticalLines.slice(index + 1)))
    }
  };

  const setLines = (event) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const mouseX = event.clientX - rect.left;
    const mouseY = event.clientY - rect.top;

    if (mode === MODE_X && !verticalLines.includes(mouseX)) {
      setVerticalLines([...verticalLines, mouseX]);
    }

    if (mode === MODE_Y && !horizontalLines.includes(mouseY)) {
      setHorizontalLines([...horizontalLines, mouseY]);
    }
  };

  const handleMouseMove = (event) => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    // clear canvas
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.fillStyle = canvasColor;
    context.fillRect(0, 0, context.canvas.width, context.canvas.height);

    const mouseY = event.clientY - canvas.getBoundingClientRect().top;
    const mouseX = event.clientX - canvas.getBoundingClientRect().left;

    // set current mouse coordinates for info display
    setHoverTop(mouseY);
    setHoverLeft(mouseX);

    drawMarkers();
    drawMultipleLines(canvas, horizontalLines, verticalLines);

    if (mode === MODE_Y) {
      // draw horizontal line at mouse Y coordinate
      drawHorizontalLine(context, canvas.width, mouseY);
    } else if (mode === MODE_X) {
      // draw vertical line at mouse X coordinate
      drawVerticalLine(context, canvas.height, mouseX);
    }
  };

  const handleSubmit = async () => {
    try {
      const restOperation = post({
        apiName: 'coordinates',
        path: '/coordinates',
        options: {
          body: {
            verticalLines: verticalLines.map(line => convertTo(line, false)),
            horizontalLines: horizontalLines.map(line => convertTo(line, false)),
            system,
            canvasWidth: convertTo(canvasWidth, false),
            canvasHeight: convertTo(canvasHeight, false),
          }
        }
      });
      const response = await restOperation.response;
      console.log('POST call succeeded');
      console.log(response);
    } catch (e) {
      console.log('POST call failed: ', e);
    }
  };

  const validateLine = (array, value, verticalOrHorizontal) => {
    if (array.includes(value)) {
      return canvasCopy.validation.duplicate;
    }

    if (value < 0) {
      return canvasCopy.validation.negative;
    }

    if (verticalOrHorizontal === HORIZONTAL && value > canvasHeight) {
      return canvasCopy.validation.outsideBounds;
    }

    if (verticalOrHorizontal === VERTICAL && value > canvasWidth) {
      return canvasCopy.validation.outsideBounds;
    }

    return null;
  };

  const validateCanvasSize = ({ width, height }) => {
    if (width && (width > maxCanvasWidthInPixels)) {
      return canvasCopy.validation.maxCanvasWidth;
    }

    if (height && (height > maxCanvasHeightInPixels)) {
      return canvasCopy.validation.maxCanvasHeight;
    }

    if (width && (width < minCanvasWidthInPixels)) {
      return canvasCopy.validation.minCanvasWidth;
    }

    if (height && (height < minCanvasHeightInPixels)) {
      return canvasCopy.validation.minCanvasHeight;
    }

    return null;
  }

  const handleKeyDown = (e, mode) => {
    if (e.key === 'Enter' && mode === VERTICAL) {
      const xInPixels = deconvertTo(tempInputXValue);
      const errorMessage = validateLine(verticalLines, xInPixels, VERTICAL);

      if (!errorMessage) {
        setVerticalLines([...verticalLines, xInPixels]);
        setTempInputXValue('');
        return;
      } else {
        setVerticalLineErrorMessage(errorMessage);
      }
    }

    if (e.key === 'Enter' && mode === HORIZONTAL ) {
      const yInPixels = deconvertTo(tempInputYValue);
      const errorMessage = validateLine(horizontalLines, yInPixels, HORIZONTAL);

      if (!errorMessage) {
        setHorizontalLines([...horizontalLines, yInPixels]);
        setTempInputYValue('');
      } else {
        setHorizontalLineErrorMessage(errorMessage);
      }
    }
  };

  const handleClickEnter = () => {
    if (mode === MODE_Y) {
      const yInPixels = deconvertTo(tempInputYValue);
      const errorMessage = validateLine(horizontalLines, yInPixels, HORIZONTAL);

      if (!errorMessage) {
        setHorizontalLines([...horizontalLines, yInPixels]);
        setTempInputYValue('');
      } else {
        setHorizontalLineErrorMessage(errorMessage);
      }
    }

    if (mode === MODE_X) {
      const xInPixels = deconvertTo(tempInputXValue);
      const errorMessage = validateLine(verticalLines, xInPixels, VERTICAL);

      if (!errorMessage) {
        setVerticalLines([...verticalLines, xInPixels]);
        setTempInputXValue('');
        return;
      } else {
        setVerticalLineErrorMessage(errorMessage);
      }
    }
  };

  const handleInputChange = (value, verticalOrHorizontal) => {
    if (verticalOrHorizontal === VERTICAL) {
      setVerticalLineErrorMessage('');
      setTempInputXValue(value);
    }

    if (verticalOrHorizontal === HORIZONTAL) {
      setHorizontalLineErrorMessage('');
      setTempInputYValue(value);
    }
  };

  const handleCanvasSizeChange = (value, widthOrHeight) => {
    setCanvasErrorMessage('');
  
    if (widthOrHeight === HEIGHT) {
      setTempCanvasHeight(value);
    }

    if (widthOrHeight === WIDTH) {
      setTempCanvasWidth(value);
    }
  };

  const getNewDimension = (widthOrHeight) => {
    if (widthOrHeight === WIDTH) {
      const widthInPixels = deconvertTo(tempCanvasWidth);
      return Math.min(Math.max(widthInPixels, minCanvasWidthInPixels), maxCanvasWidthInPixels);
    }
    if (widthOrHeight === HEIGHT) {
      const heightInPixels = deconvertTo(tempCanvasHeight);
      return Math.min(Math.max(heightInPixels, minCanvasHeightInPixels), maxCanvasHeightInPixels);
    }
  }

  const handleChangeCanvasDimensions = (widthOrHeight) => {
    const canvas = canvasRef.current;

    if (widthOrHeight === WIDTH) {
      const calculatedWidth = deconvertTo(tempCanvasWidth);
      const newWidth = getNewDimension(WIDTH);
      const errorMessage = validateCanvasSize({ width: calculatedWidth });
      if (errorMessage) {
        setCanvasErrorMessage(errorMessage);
        return;
      }
  
      setCanvasWidth(newWidth);
      drawMarkers();
      drawMultipleLines(canvas, horizontalLines, verticalLines);
    }

    if (widthOrHeight === HEIGHT) {
      const calculatedHeight = deconvertTo(tempCanvasHeight);
      const newHeight = getNewDimension(HEIGHT);
      const errorMessage = validateCanvasSize({ height: calculatedHeight });
      if (errorMessage) {
        setCanvasErrorMessage(errorMessage);
        return;
      }

      setCanvasHeight(newHeight);
      drawMarkers();
      drawMultipleLines(canvas, horizontalLines, verticalLines);
    }
  };

  useEffect(() => {
    // create the canvas background on initial render
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    context.fillStyle = canvasColor;
    context.fillRect(0, 0, context.canvas.width, context.canvas.height);
    drawMarkers();
    drawMultipleLines(canvas, horizontalLines, verticalLines);
    // eslint-disable-next-line
  }, []);

  useEffect(() => drawMultipleLines(canvasRef.current, horizontalLines, verticalLines), [horizontalLines, verticalLines]);

  useEffect(() => {
    // const newTempCanvasWidth = convertTo(canvasWidth, false);
    // setTempCanvasWidth(newTempCanvasWidth);

    // const newTempCanvasHeight = convertTo(canvasHeight, false);
    // setTempCanvasHeight(newTempCanvasHeight);
    const canvas = canvasRef.current;
    drawMarkers();
    drawMultipleLines(canvas, horizontalLines, verticalLines);
    // eslint-disable-next-line
  }, [system])

  return (
    <div className="canvas-container">
      <Card className="header" variation="elevated">
        {canvasCopy.header}
        <span>
            <Button className="submit-button" disabled={!verticalLines.length && !horizontalLines.length} onClick={handleSubmit}>
              {canvasCopy.submit}
            </Button>
            {props.children}
        </span>
      </Card>

      <Card variation="elevated" className="body-container">
        <Card variation="outlined" className="toolbox-container">
          <div className="subheading">{canvasCopy.toolbox}</div>

          <div className="toolbox">
            <div>
              <label>{canvasCopy.setLine}</label>
              <ToggleButtonGroup
                value={mode}
                isExclusive
                onChange={(value) => setMode(value)}
              >
                <ToggleButton value={MODE_X}>
                  {MODE_X}
                </ToggleButton>
                <ToggleButton value={MODE_Y}>
                  {MODE_Y}
                </ToggleButton>
              </ToggleButtonGroup>
            </div>

            <div>
              <label>{canvasCopy.system}</label>
              <ToggleButtonGroup
                value={system}
                isExclusive
                onChange={(value) => setSystem(value)}
              >
                <ToggleButton value={IMPERIAL}>
                  {IMPERIAL}
                </ToggleButton>
                <ToggleButton value={METRIC}>
                  {METRIC}
                </ToggleButton>
              </ToggleButtonGroup>
            </div>

            <div>
              <label>{canvasCopy.canvasDimensions}</label>
              <div>
                <Grid
                    templateColumns="50px 80px 50px"
                    templateRows="1fr 1fr"
                    gap="8px"
                  >
                    <label>{canvasCopy.width}&nbsp;</label>
                    <Input size="small" type="number" step="1" name="canvasWidth" value={tempCanvasWidth} onChange={(e) => handleCanvasSizeChange(e.target.value, WIDTH)} onBlur={() => handleChangeCanvasDimensions(WIDTH)} />
                    <span className="align-center">{system === IMPERIAL ? 'feet' : 'meters'}</span>

                    <label>{canvasCopy.height}&nbsp;</label>
                    <Input size="small" type="number" step="1" name="canvasHeight" value={tempCanvasHeight} onChange={(e) => handleCanvasSizeChange(e.target.value, HEIGHT)} onBlur={() => handleChangeCanvasDimensions(HEIGHT)} />
                    <span className="align-center">{system === IMPERIAL ? 'feet' : 'meters'}</span>
                </Grid>
                <div className="error-message">
                  {canvasErrorMessage}
                </div>
              </div>
            </div>

            <div>
              <label>{canvasCopy.clear}</label>
              <div>
                <Button className="clear-button" size="small" onClick={() => setHorizontalLines([])}>
                  {canvasCopy.clearHorizontal}
                </Button>
              </div>
              <div>
                <Button className="clear-button" size="small" onClick={() => setVerticalLines([])}>
                  {canvasCopy.clearVertical}
                </Button>
              </div>
            </div>

            <div>
              <label>{canvasCopy.createX}</label>
              <div>
                <Input size="small" disabled={mode === MODE_Y} type="number" value={tempInputXValue} onChange={(e) => handleInputChange(e.target.value, VERTICAL)} onKeyDown={(e) => handleKeyDown(e, VERTICAL)} />
                <Button className="input-button" size="small" onClick={handleClickEnter}>
                  {canvasCopy.enter}
                </Button>
              </div>
              <div className="error-message">
                {verticalLineErrorMessage}
              </div>
            </div>

            <div>
              <label>{canvasCopy.createY}</label>
              <div>
                <Input size="small" disabled={mode === MODE_X} type="number" value={tempInputYValue} onChange={(e) => setTempInputYValue(e.target.value)} onKeyDown={(e) => handleKeyDown(e, HORIZONTAL)}/>
                <Button className="input-button" size="small" onClick={handleClickEnter}>
                  {canvasCopy.enter}
                </Button>
              </div>
              <div className="error-message">
                {horizontalLineErrorMessage}
              </div>
            </div>
          </div>
        </Card>

        <div className="display-container">
          <div className="subheading">{canvasCopy.display}</div>
          <div className="current-coordinates">
            <span><label>X:</label> {mode === MODE_X && convertTo(hoverLeft)}</span>
            <span><label>Y:</label> {mode === MODE_Y && convertTo(hoverTop)}</span>
          </div>

          {
            verticalLines[0] &&
            <div>
              <label>{canvasCopy.verticalLines}</label>
              <div className="set-lines">
                {verticalLines.map((x, idx) => {
                  return (
                    <Pill display={convertTo(x)} value={x} key={`vertical-${idx}`} handleClick={(value) => removeLine(value, VERTICAL) } />
                  )
                })}
              </div>
            </div>
          }

          {
            horizontalLines[0] &&
            <div>
              <label>{canvasCopy.horizontalLines}</label>
              <div className="set-lines">
                {horizontalLines.map((x, idx) => {
                  return (
                    <Pill display={convertTo(x)} value={x} key={`horizontal-${idx}`} handleClick={(value) => removeLine(value, HORIZONTAL) } />
                  )
                })}
              </div>
            </div>
          }
        </div>

        <div className="container" style={{ width: `${canvasWidth}px`, height: `${canvasHeight}px` }}>
          <canvas ref={canvasRef} {...props} onClick={(e) => setLines(e)} onMouseMove={handleMouseMove} onMouseLeave={resetHover}/>
          {/* <div id="tooltip" style={{ left: `${hoverLeft}px`, top: `${hoverTop}px` }}>
            {convertTo(hoverLeft)}
          </div> */}
        </div>
      </Card>
    </div>
  );
}

export default Canvas;