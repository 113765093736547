export const FT_TO_METER = 0.3048;
export const FT_TO_PIXEL = 15;
export const MARKER_LENGTH = 10;

export const MODE_X = 'X';
export const MODE_Y = 'Y';
export const METRIC = 'METRIC';
export const IMPERIAL = 'IMPERIAL';
export const HORIZONTAL = 'HORIZONTAL';
export const VERTICAL = 'VERTICAL';
export const WIDTH = 'WIDTH';
export const HEIGHT = 'HEIGHT';

export const minCanvasWidthInFt = 36;
export const minCanvasHeightInFt = 36;
export const minCanvasWidthInPixels = minCanvasWidthInFt * FT_TO_PIXEL;
export const minCanvasHeightInPixels = minCanvasHeightInFt * FT_TO_PIXEL;

export const maxCanvasWidthInFt = 72;
export const maxCanvasHeightInFt = 72;
export const maxCanvasWidthInPixels = maxCanvasWidthInFt * FT_TO_PIXEL;
export const maxCanvasHeightInPixels = maxCanvasHeightInFt * FT_TO_PIXEL;

export const canvasColor = '#ffffff';
export const canvasLineColor = '#000000';

export const copy = {
  Canvas: {
    header: 'Create your own cubbies',
    toolbox: 'Toolbox',
    setLine: 'Set Line',
    system: 'System of Measurement',
    canvasDimensions: 'Canvas Dimensions',
    width: 'Width',
    height: 'Height',
    createY: 'Set Horizontal Lines',
    createX: 'Set Vertical Lines',
    horizontalLines: 'Horizontal Lines',
    verticalLines: 'Vertical Lines',
    enter: 'Enter',
    clear: 'Clear Lines',
    clearHorizontal: 'Clear Horizontal Lines',
    clearVertical: 'Clear Vertical Lines',
    display: 'Display',
    submit: 'Submit',
    validation: {
      duplicate: 'This value has already been entered.',
      outsideBounds: 'You cannot draw a line outside of the canvas.',
      negative: 'You cannot have a negative number.',
      maxCanvasHeight: `Max canvas height is ${maxCanvasHeightInFt} feet or ${parseFloat((maxCanvasHeightInFt * FT_TO_METER).toFixed(2))} meters.`,
      minCanvasHeight: `Minimum canvas height is ${minCanvasHeightInFt} feet or ${parseFloat((minCanvasHeightInFt * FT_TO_METER).toFixed(2))} meters.`,
      maxCanvasWidth: `Max canvas width is ${maxCanvasWidthInFt} feet or ${parseFloat((maxCanvasWidthInFt * FT_TO_METER).toFixed(2))} meters.`,
      minCanvasWidth: `Minimum canvas width is ${minCanvasWidthInFt} feet or ${parseFloat((minCanvasWidthInFt * FT_TO_METER).toFixed(2))} meters.`,
    }
  }
};
